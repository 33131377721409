<template>
  <!-- 出库 -->
  <div class="lading-bill-ex-warehouse page-info-content">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-position="left"
      :inline="true"
      label-width="130px"
      class="demo-ruleForm page-form form-item-row"
    >
      <el-form-item label="提货单号">
        <el-input v-model="ruleForm.deliveryNo" disabled placeholder="提货单号" />
      </el-form-item>
      <el-form-item label="货主名称" prop="custName">
        <el-input v-model="ruleForm.custName" disabled placeholder="货主名称" />
      </el-form-item>
      <el-form-item label="仓库名称" prop="warehouseName">
        <el-input v-model="ruleForm.warehouseName" disabled placeholder="仓库名称" />
      </el-form-item>
      <el-form-item label="提货公司">
        <el-input v-model="ruleForm.downCustName" placeholder="请输入提货公司" disabled />
      </el-form-item>
      <el-form-item label="司机姓名" :rules="rules.name" prop="driverName">
        <el-input v-model="ruleForm.driverName" placeholder="请输入司机姓名" />
      </el-form-item>
      <el-form-item label="司机电话" :rules="rules.phone" prop="driverPhone">
        <el-input v-model="ruleForm.driverPhone" type="number" placeholder="请输入司机电话" />
      </el-form-item>
      <el-form-item label="车牌号" :rules="rules.carNo" prop="carNo">
        <el-input v-model="ruleForm.carNo" placeholder="请输入车牌号" />
      </el-form-item>
      <el-form-item
        label="提单校验码"
        :rules="[{ min: 6, max: 6, message: '提单验证码为6位数', trigger: 'blur' }, ...rules.name]"
        prop="checkCode"
      >
        <el-input v-model="ruleForm.checkCode" placeholder="请输入出库验证码" type="number" />
      </el-form-item>
      <el-form-item label="仓库出库员" :rules="rules.name" prop="warehouseKeeper">
        <el-input v-model="ruleForm.warehouseKeeper" placeholder="请输入仓库出库员" />
      </el-form-item>
      <el-form-item label="出库时间" :rules="rules.selectiveType" prop="outDate">
        <el-date-picker
          v-model="ruleForm.outDate"
          :picker-options="pickerOptionsStart"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="选择日期时间"
        />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="ruleForm.remark" placeholder="请输入备注" />
      </el-form-item>
      <el-form-item class="lastOne" label="">
        <Table ref="wmsInventoryIndetail" :item-data="itemData" :list-data="wmsInventoryIndetailList">
          <template slot="dynamicInsert">
            <el-table-column type="expand">
              <template slot-scope="props">
                <table class="tableBox">
                  <tr>
                    <td>出库数量(吨)</td>
                    <td>子仓库编号</td>
                    <td>货位号</td>
                    <td>货位描述</td>
                    <td>钢卷号</td>
                    <td>件重(吨)</td>
                    <td>库存数量(吨)</td>
                  </tr>
                  <tr v-for="(item, index) in props.row.childArr" :key="index">
                    <td>
                      <el-input-number
                        v-model="item.invWeight"
                        :precision="3"
                        :controls="false"
                        :max="item.detailInvWeight"
                        @change="checkList(item, props.row.childArr, props.row)"
                      />
                    </td>
                    <td>{{ item.detailWarehouseNo }}</td>
                    <td>{{ item.detailLocationNo }}</td>
                    <td>{{ item.detailLocationDesc }}</td>
                    <td>{{ item.detailCoilNo }}</td>
                    <td>{{ item.detailWeights }}</td>
                    <td>{{ item.detailInvWeight }}</td>
                  </tr>
                  <tr v-if="props.row.childArr.length === 0">
                    <td colspan="7">
                      <span class="noData" @click="choiceChoice(props.row, props.$index)">暂无数据，请添加</span>
                    </td>
                  </tr>
                </table>
              </template>
            </el-table-column>
          </template>
          <template #stock="{ row, index }">
            <span class="choice" @click="choiceChoice(row, index)">选择</span>
          </template>
        </Table>
      </el-form-item>
    </el-form>
    <div class="buttonCombination">
      <el-button size="small" plain class="widen-button" @click="$router.go(-1)">
        取消
      </el-button>
      <el-button
        :loading="$store.state.loading"
        size="small"
        type="primary"
        class="widen-button"
        @click="submitForm('ruleForm')"
      >
        确定
      </el-button>
    </div>
    <ChoiceInventory ref="choiceInventory" :params="params" @getInventory="getInventory" />
  </div>
</template>

<script>
import Table from '@/components/Table'
import ChoiceInventory from '../components/ChoiceInventory'
import { rules } from '@/utils/matchFormRegex'
import { deliveryInfoDetail } from '@/api/generationMining'
import { deliveryInfoOutBoundDelivery } from '@/api/warehousingSystem'
export default {
  components: { Table, ChoiceInventory },
  data() {
    return {
      params: {},
      pickerOptionsStart: {
        // 时间不能大于当前时间
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },
      ruleForm: {
        carNo: '',
        checkCode: '',
        downCustName: '',
        driverName: '',
        deliveryNo: '',
        driverPhone: '',
        outDate: ''
      },
      pnid: '',
      spreadNum: 0,
      whetherRemove: true,
      warehouseListArr: [],
      cmpNameArr: [],
      rules,
      wmsInventoryIndetailList: [
        {}
      ], // 选择好出库的库存
      itemData: [
        { label: '品名', prop: 'goodsName' },
        { label: '产地', prop: 'goodsProduct' },
        { label: '规格', prop: 'goodsSpecs' },
        { label: '件重(吨)', prop: 'goodsWeights' },
        // { label: '申请数量', prop: 'applyAmount' },
        { label: '申请数量(吨)', prop: 'applyWeight' },
        { label: '选择库存', slotName: 'stock' },
        // { label: '实提数量', slotName: 'invAmount' },
        { label: '本次可提数量(吨)', prop: 'invWeight' }
      ],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/warehousingSystem/ladingBillManagement', name: '提单管理' }, { path: '', name: '出库' }]
    }
  },
  created() {
    if (this.$route.query.id) {
      deliveryInfoDetail(this.$route.query.id, res => {
        const data = { ...res.data }
        this.ruleForm.custId = data.fundsCmpCode
        this.ruleForm.deliveryNo = data.deliveryNo
        this.ruleForm.warehouseId = data.deliveryWarehouseId
        this.ruleForm.warehouseName = data.deliveryWarehouse
        this.ruleForm.deliveryCheckCode = data.deliveryCheckCode
        this.ruleForm.carNo = data.deliveryCarNo
        this.ruleForm.downCustName = data.custCmpName
        this.ruleForm.driverName = data.driverName
        this.ruleForm.driverPhone = data.driverPhone
        this.ruleForm.consigneeId = data.custCmpCode
        this.ruleForm.deliveryId = data.id
        this.ruleForm.consigneeName = data.custCmpName
        this.ruleForm.custName = data.fundsCmpName
        this.params.custId = data.fundsCmpCode
        this.params.downCustName = data.custCmpName
        this.params.warehouseId = data.deliveryWarehouseId
        this.wmsInventoryIndetailList = [...data.goodsDetailList] || []
        // 把要出库的数据整成一个数组
        this.wmsInventoryIndetailList.forEach((item, index) => {
          this.$set(this.wmsInventoryIndetailList, index, {
            ...item,
            stock: true,
            invWeight: item.applyWeight,
            invAmount: item.applyAmount,
            upperLimit: (Number(item.applyWeight) * 1.01).toFixed(3),
            floor: (Number(item.applyWeight) * 0.99).toFixed(3),
            childArr: []
          })
        })
      })
    }
  },
  methods: {
    checkList(term, arr, row) {
      this.$forceUpdate()
      if (Number(term.invWeight) < 0) {
        this.$set(term, 'invWeight', '')
        return
      }
      let num = 0
      arr.forEach(item => {
        num += Number(item.invWeight)
      })
      if (num > Number(row.upperLimit)) {
        this.$message.error('出库数量不能超过申请数量')
        this.$set(term, 'invWeight', '')
        return
      }
    },
    // 选择库存明细  获取数组的信息赋予他的子数组
    getInventory(row) {
      const arr = []
      row.forEach(item => {
        arr.push({
          ...item,
          detailId: item.detailId,
          inventoryId: item.detailInventoryId,
          invWeight: ''
        })
      })
      this.$set(this.wmsInventoryIndetailList[this.params.index], 'childArr', [...arr])
      this.$forceUpdate()
    },
    // 点击弹出库存明细弹窗
    choiceChoice(data, index) {
      this.params.goodsId = data.goodsId
      this.params.materials = data.goodsMaterials
      this.params.productions = data.goodsProduct
      this.params.specs = data.goodsSpecs
      this.params.index = index
      this.$refs.wmsInventoryIndetail.handlerSpread(data, true)
      if (!this.params.warehouseId || !this.params.custId) {
        this.$message.error('数据有误，请先确认！')
        return
      }
      this.$refs.choiceInventory.dialogTableVisible = true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.deliveryCheckCode !== this.ruleForm.checkCode) {
            this.$message.error('提单校验码错误！')
            return
          }
          let flag = true; const arr = []
          this.wmsInventoryIndetailList.forEach(item => {
            let num = 0
            if (item.childArr && item.childArr.length > 0) {
              item.childArr.forEach(val => {
                num += Number(val.invWeight)
                if (Number(val.invWeight) > 0) {
                  arr.push(val)
                }
              })
            }
            if (num > Number(item.upperLimit) || num < Number(item.floor)) {
              flag = false
            }
          })
          if (!flag) {
            this.$message.error('请将提货单里的商品全部出库')
            return
          }
          if (arr.length === 0) {
            this.$message.error('请先选择存库和填写入库数据')
            return
          }
          this.ruleForm.wmsInventoryOutdetailReqList = [...arr]
          deliveryInfoOutBoundDelivery(this.ruleForm, () => {
            this.$message.success('提单出货成功')
            this.$router.go(-1)
          })
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }

}
</script>

<style lang="scss">

.lading-bill-ex-warehouse .el-form {
  .tableBox {
    margin-left: 16px;
    text-align: center;
    padding: 0 16px;
    width: calc(100% - 32px);
    border-collapse: collapse;

    td {
      width: 150px;
      height: 40px;
      border: 1px solid #ededed;

      .noData {
        cursor: pointer;
        color: $assistColor4;
      }
    }
  }

  .link {
    color: rgb(64, 158, 255);
    text-decoration: underline;
    cursor: pointer;
  }

  .choice {
    text-decoration: underline;
    color: #66b1ff;
    cursor: pointer;
  }
}

.lading-bill-ex-warehouse .buttonCombination {
  width: 100%;
  text-align: center;
}

.lading-bill-ex-warehouse .el-form .lastOne {
  width: 100%;

  .title {
    color: #333;
    font-weight: 700;
    float: left;
  }

  .el-form-item__label {
    color: #333;
    font-weight: 700;
  }

  .el-form-item__content {
    text-align: center;
    padding: 0 32px;
    width: calc(100% - 64px);
    overflow-y: auto;
  }
}

.lading-bill-ex-warehouse .el-form .el-input__inner,
.lading-bill-ex-warehouse .el-form .el-select,
.lading-bill-ex-warehouse .el-form .el-input,
.lading-bill-ex-warehouse .el-form .el-cascader {
  width: 100%;
}
</style>
